import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import smartlookClient from 'smartlook-client';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.enableSentry) {
  Sentry.init({
    dsn: 'https://fab1b57adef5433bb3bca65b3e3ce4e3@o735829.ingest.sentry.io/4503934665818112',
    integrations: [
      new BrowserTracing({
        tracingOrigins: environment.tracingOrigins,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    environment: environment.production ? 'production' : 'test',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
}

if (environment.enableSmartlook) {
  smartlookClient.init('0a9f54cee3c5f094bab9e139a3300dc3b29e29e9', {
    region: 'eu',
    advancedNetwork: {},
    interceptors: {
      network: (data, context) => {
        const tokenIndex = data.url.indexOf('token=');
        if (tokenIndex > -1) {
          data.url = data.url.slice(0, tokenIndex) + 'token=[OBSCURED]';
        }
      },
    },
  });
  smartlookClient.record({ forms: true, numbers: false, emails: false, ips: false });
}

if (environment.production) {
  enableProdMode();
}

// Log offline / online transitions to help diagnose response:0 "unknown error" in Sentry
window.addEventListener('offline', (e) => {
  console.log('offline');
});

window.addEventListener('online', (e) => {
  console.log('online');
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
