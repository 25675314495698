import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { SSOID_LOGIN_PATH } from '../constants/paths';

@Injectable()
export class HttpClientUnauthorizedInterceptor implements HttpInterceptor {
  private readonly unauthorizedInterceptPatterns = [
    /^https?:\/\/(?!.*auth\.).*\.datch\.cloud.*/,
    /^https?:\/\/(?!.*auth\.).*\.datch\.io.*/,
    /^https?:\/\/localhost.*/,
  ];

  public constructor(private readonly router: Router) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (this.unauthorizedInterceptPatterns.some((pattern) => pattern.test(req.url))) {
            console.error(
              `Received status ${error.status} from ${req.method} ${req.url}. Redirecting to ${SSOID_LOGIN_PATH}.`,
            );
            this.router.navigate([SSOID_LOGIN_PATH]).catch(console.error);
          }
        }
        return throwError(() => error);
      }),
    );
  }
}
