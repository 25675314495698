import { HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { CompanyStorageService } from './company-storage.service';
import { SSOID_LOGIN_PATH } from '../../constants/paths';

/**
 * Set this header to skip the bearer token interceptor dynamically per request
 * By default, the bearer token interceptor is added to all requests
 */
export const X_SKIP_INTERCEPTOR = 'X-Skip-Interceptor';

export const initializer = (
  keycloak: KeycloakService,
  storageService: CompanyStorageService,
  router: Router,
): (() => Promise<void>) => {
  return async () => {
    const companyInfo = storageService.getCompanyInfo();
    if (companyInfo) {
      try {
        keycloak.keycloakEvents$.subscribe((event) => {
          if (event.type === KeycloakEventType.OnAuthRefreshError) {
            console.error(`Could not refresh auth token. Redirecting to ${SSOID_LOGIN_PATH}.`);
            router.navigate([SSOID_LOGIN_PATH]).catch(console.error);
          }
        });
        await keycloak.init({
          config: companyInfo.authConfig,
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
          },
          enableBearerInterceptor: true,
          shouldAddToken: (request: HttpRequest<unknown>) => {
            return !request.headers.has(X_SKIP_INTERCEPTOR);
          },
        });
      } catch (error: unknown) {
        console.error('keycloak init error', error);
        storageService.clearCompanyInfo();
      }
    }
  };
};
