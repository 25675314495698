import { MEDIA_API_BASE_URL, MediaApiClientModule } from '@datch/ngx-media-api-client';
import { ASSETS_API_BASE_URL, LOCATIONS_API_BASE_URL, ResourcesMSClientModule } from '@datch/ngx-resources-ms-client';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, forwardRef } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { DragulaModule } from 'ng2-dragula';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializer } from './@core/data/app-initializer';
import { AuthGuard } from './auth-guard.service';
import { environment } from '../environments/environment';
import { APIClientModule } from './@core/data';
import { CompanyStorageService } from './@core/data/company-storage.service';
import { PipesModule } from './pipes/pipes.module';
import { SplashscreenComponent } from './splashscreen/splashscreen.component';
import { CoreModule } from './@core/core.module';
import { HttpClientUnauthorizedInterceptor } from './interceptors/http-client-unauthorized-interceptor';

@NgModule({
  declarations: [AppComponent, SplashscreenComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    KeycloakAngularModule,
    MatSnackBarModule,
    NgbModule,
    APIClientModule.forRoot({
      domain: environment.apiUrl,
      httpOptions: {
        headers: { 'Content-Type': 'application/json' },
      },
    }),
    DragulaModule.forRoot(),
    PipesModule.forRoot(),
    CoreModule.forRoot(),
    MediaApiClientModule,
    ResourcesMSClientModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    CompanyStorageService,
    KeycloakService,
    HttpClient,
    AuthGuard,
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, CompanyStorageService, Router],
    },
    {
      provide: MEDIA_API_BASE_URL,
      useValue: environment.mediaServiceUrl,
    },
    {
      provide: LOCATIONS_API_BASE_URL,
      useValue: environment.locationServiceUrl,
    },
    {
      provide: ASSETS_API_BASE_URL,
      useValue: environment.assetServiceUrl,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientUnauthorizedInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
