import { Component, AfterViewInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { KeycloakService } from 'keycloak-angular';
import { ViewModeService, ViewModes } from '../@core/utils/view-mode.service';
import { CompanyStorageService } from '../@core/data/company-storage.service';
import { initializer } from '../@core/data/app-initializer';
import { CompanyDto } from '../@core/data';
import { environment } from '../../environments/environment';
import { isRealmValid } from '../@core/utils/sanitizer';
import { SSOID_LOGIN_PATH } from '../constants/paths';

const TAG = '[SplashscreenComponent]';

@Component({
  selector: 'ngx-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss'],
})
export class SplashscreenComponent implements AfterViewInit {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
    private readonly viewModeService: ViewModeService,
    private readonly companyService: CompanyStorageService,
    private readonly keycloakService: KeycloakService,
  ) {}

  public ngAfterViewInit(): void {
    let companyInfo = this.companyService.getCompanyInfo();
    const realmQueryParam = this.getRealmQueryParam();

    if (this.isCompanyRealmUpdateRequired(companyInfo, realmQueryParam)) {
      this.companyService.clearCompanyInfo();
      companyInfo = this.companyService.getCompanyInfo();
      this.keycloakService.logout();
      return;
    }

    if (!this.companyService.getCompanyInfo() && realmQueryParam) {
      this.saveCompanyInfo({
        authConfig: {
          clientId: environment.keycloak.clientId,
          url: environment.keycloak.url,
          realm: realmQueryParam,
        },
        authLoginOptions: {
          redirectUri: environment.dashboardUrl,
        },
        domain: realmQueryParam,
      });
      companyInfo = this.companyService.getCompanyInfo();
    }

    this.viewModeService.getViewMode().then((viewMode) => {
      const keycloak = this.keycloakService.getKeycloakInstance();
      if (viewMode) {
        if (viewMode === ViewModes.POPULATE || viewMode === ViewModes.ONLY_FORMS) {
          this.router.navigate(['pages', 'forms', 'form-type']);
        } else if (viewMode === ViewModes.RAISE || viewMode === ViewModes.ONLY_RAISE) {
          this.router.navigate(['pages', 'quick-start']);
        } else {
          this.router.navigate(['pages', 'unauthorized']);
        }
      } else if (companyInfo && keycloak && keycloak.realm !== companyInfo.authConfig.realm) {
        this.reinitializeKeycloak(companyInfo);
      } else if (companyInfo && keycloak) {
        this.keycloakService.login(companyInfo.authLoginOptions);
      } else if (companyInfo) {
        this.reinitializeKeycloak(companyInfo);
      } else {
        this.router.navigate([SSOID_LOGIN_PATH]);
      }
    });
  }

  private reinitializeKeycloak(companyInfo: CompanyDto): Promise<void> {
    return initializer(this.keycloakService, this.companyService, this.router)()
      .then(() => {
        this.keycloakService.login(companyInfo.authLoginOptions);
      })
      .catch((error) => {
        console.log(TAG, 'initialized error', error);
      });
  }

  private isCompanyRealmUpdateRequired(companyInfo: CompanyDto, queryParamRealm: string): boolean {
    return companyInfo && queryParamRealm && companyInfo.authConfig.realm !== queryParamRealm;
  }

  private getRealmQueryParam(): string | undefined {
    const realm = new URLSearchParams(this.document.location.search).get('realm');
    if (isRealmValid(realm)) {
      return realm;
    }
    console.error(TAG, 'Invalid realm query param found', realm);
  }

  private saveCompanyInfo(companyDto: CompanyDto): void {
    this.companyService.setCompanyInfo(companyDto);
  }
}
