import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://test.auth.datch.io/auth',
  realm: 'unknown',
  clientId: 'dashboard',
};

export const environment = {
  production: false,
  apiUrl: 'https://test-api.datch.cloud',
  dashboardUrl: 'https://test.dashboard.datch.io',
  keycloak: keycloakConfig,
  formBuilderUrl: 'https://test-app.datch.io/builder',
  enableSentry: true,
  enableProductFruits: true,
  enableAnalytics: true,
  mixPanelAPIKey: '33b3a4ee4d49ca854e1d579a8d7e5911',
  productFruitsWorkspaceCode: 'JfQJUEh23liJvFhw',
  enableSmartlook: true,
  tracingOrigins: ['https://test.dashboard.datch.io'],
  mediaServiceUrl: 'https://test-api.datch.cloud/media',
  locationServiceUrl: 'https://test-api.datch.cloud/locations',
  assetServiceUrl: 'https://test-api.datch.cloud/assets',
};
