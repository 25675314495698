import { Component } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { NbIonIcons } from '../assets/icons/nebular-ionicons';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  public constructor(private readonly iconsLibrary: NbIconLibraries) {
    this.iconsLibrary.registerSvgPack('ionicons', NbIonIcons);
  }
}
